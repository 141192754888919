<template>
  <div>
    <BreadCrumbs v-if="breadcrumbs" :breadcrumbs="breadcrumbs" />

    <v-card>
      <v-card-title>BUSCADOR</v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="d-flex flex-row">
            <v-text-field
              v-model="filters.search"
              clearable
              placeholder="Busca por nome do usuário"
            ></v-text-field>
            <v-btn class="mx-2 my-3" color="primary" @click="loadLinkedUsers()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn @click="clearFilters()" class="mx-2 my-3">Limpar</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header> BUSCADOR </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col class="d-flex flex-row">
              <v-text-field
                v-model="filters.search"
                clearable
                placeholder="Busca por nome do usuário"
              ></v-text-field>
              <v-btn
                class="mx-2 my-3"
                color="primary"
                @click="loadLinkedUsers()"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn @click="clearFilters()" class="mx-2 my-3">Limpar</v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->

    <v-card class="mt-2">
      <v-card-title>
        Usuários vinculados {{ formattedInstitutionName }}
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="users"
          disable-pagination
          hide-default-footer
          :loading="loading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn
                rounded
                small
                color="success"
                @click="redirectToLinkUsersPage()"
                >Vincular +</v-btn
              >
            </v-toolbar>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              class="mr-2"
              title="Desvincular"
              @click="unlikUser(item.id)"
              color="error"
              icon
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination
        v-model="page"
        :length="numberOfPages"
        @input="loadLinkedUsers"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import BreadCrumbs from "@/components/template/Breadcrumbs.vue";

export default {
  components: {
    BreadCrumbs,
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: null,
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
    formattedInstitutionName() {
      return this.institutionName ? `(${this.institutionName})` : "";
    },
    institutionId() {
      return this.$route.params.id;
    },
  },
  data: () => ({
    headers: [
      { text: "Nome", value: "fullName" },
      { text: "Email", value: "email" },
      { text: "Tipo de usuário", value: "group_name" },
      { text: "Ações", value: "actions" },
    ],
    loading: false,
    users: [],
    resultsPerPage: 20,
    totalRows: 0,
    page: 1,
    institutionName: null,
    filters: {
      currentPage: 1,
      search: null,
    },
  }),
  methods: {
    async loadLinkedUsers(page) {
      try {
        this.loading = true;

        let currentPage = page ? page : 1;

        let url = `/admin/instituicoes/${this.institutionId}/usuarios`;

        const response = await this.$axios.get(url, {
          params: {
            search: this.filters.search,
            pagination: 1,
            resultsPerPage: this.resultsPerPage,
            page: currentPage,
          },
        });

        this.setUsers(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setUsers(data) {
      const cpData = { ...data };

      this.totalRows = cpData.count;

      this.users = cpData.rows.map((u) => {
        return { ...u, fullName: `${u.firstName} ${u.lastName}` };
      });

      this.institutionName = cpData.institutionName;
    },
    async unlikUser(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Atenção",
          `Deseja realmente desvincular este usuário da sala?`
        );

        if (!confirm) return;

        this.loading = true;

        let url = `/admin/instituicoes/${this.institutionId}/usuarios/${id}/`;

        await this.$axios.delete(url);

        this.unlikUserFromTable(id);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    unlikUserFromTable(id) {
      const index = this.users.findIndex((p) => p.id === id);

      this.users.splice(index, 1);
    },
    redirectToLinkUsersPage() {
      this.$router.push(
        `/admin/instituicao/${this.institutionId}/usuarios/vincular`
      );
    },
    clearFilters() {
      this.filters = {
        currentPage: 1,
        search: null,
      };

      this.loadLinkedUsers();
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  created() {
    this.loadLinkedUsers();
  },
};
</script>

<style></style>
